import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Defining a blog service";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "defining-a-blog-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#defining-a-blog-service",
        "aria-label": "defining a blog service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defining a blog service`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-you-need"
        }}>{`What you need`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-create-a-thrift-file"
        }}>{`1. Create a Thrift file`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-define-a-service"
        }}>{`2. Define a service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-compile-the-thrift-file"
        }}>{`3. Compile the Thrift file`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#next-step"
        }}>{`Next step`}</a></li>
    </ul>
    <p>{`Let's begin by defining our blog service in a Thrift file.`}</p>
    <TutorialSteps current={1} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`No preparation is required for this step. Do check that you've prepared the `}<a parentName="p" {...{
        "href": "/tutorials/thrift/blog/#prerequisites"
      }}>{`prerequisites`}</a>{`.`}</p>
    <h2 {...{
      "id": "1-create-a-thrift-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-create-a-thrift-file",
        "aria-label": "1 create a thrift file permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Create a Thrift file`}</h2>
    <p>{`Create a Thrift file, `}<inlineCode parentName="p">{`blog.thrift`}</inlineCode>{` in the `}<inlineCode parentName="p">{`{project_root}/src/main/thrift`}</inlineCode>{` folder as follows.
You can see the full version of the file `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/blob/main/tutorials/thrift/src/main/thrift/blog.thrift"
      }}>{`here`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp",
        "metastring": "filename=blog.thrift",
        "filename": "blog.thrift"
      }}>{`namespace java example.armeria.blog.thrift
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`  See `}<a parentName="p" {...{
          "href": "/tutorials/thrift/blog#sample-service"
        }}>{`Sample service structure`}</a>{` for the overall folder structure.`}</p>
    </Tip>
    <h2 {...{
      "id": "2-define-a-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-define-a-service",
        "aria-label": "2 define a service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Define a service`}</h2>
    <p>{`Let's define our blog service in the `}<inlineCode parentName="p">{`blog.thrift`}</inlineCode>{` file.
We'll add structs, an exception, and service methods.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add the `}<inlineCode parentName="p">{`BlogPost`}</inlineCode>{` struct.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-cpp",
            "metastring": "filename=blog.thrift",
            "filename": "blog.thrift"
          }}>{`struct BlogPost {
  1: i32 id;
  2: string title;
  3: string content;
  4: i64 createdAt;
  5: i64 modifiedAt;
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add structs for request and response objects as follows.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-cpp",
            "metastring": "filename=blog.thrift",
            "filename": "blog.thrift"
          }}>{`struct CreateBlogPostRequest {
  1: string title;
  2: string content;
}

struct GetBlogPostRequest {
  1: i32 id;
}

struct ListBlogPostsRequest {
  1: bool descending;
}

struct ListBlogPostsResponse {
  1: list<BlogPost> blogs;
}

struct UpdateBlogPostRequest {
  1: i32 id;
  2: string title;
  3: string content;
}

struct DeleteBlogPostRequest {
  1: i32 id;
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the `}<inlineCode parentName="p">{`BlogNotFoundException`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-cpp",
            "metastring": "filename=blog.thrift",
            "filename": "blog.thrift"
          }}>{`exception BlogNotFoundException {
  1: string reason
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a service with methods for create, read, update, and delete operations.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-cpp",
            "metastring": "filename=blog.thrift",
            "filename": "blog.thrift"
          }}>{`service BlogService {
  BlogPost createBlogPost(1:CreateBlogPostRequest request),

  BlogPost getBlogPost(1:GetBlogPostRequest request) throws (1:BlogNotFoundException e),

  ListBlogPostsResponse listBlogPosts(1:ListBlogPostsRequest request),

  BlogPost updateBlogPost(1:UpdateBlogPostRequest request) throws (1:BlogNotFoundException e),

  void deleteBlogPost(1:DeleteBlogPostRequest request) throws (1:BlogNotFoundException e),
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "3-compile-the-thrift-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-compile-the-thrift-file",
        "aria-label": "3 compile the thrift file permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Compile the Thrift file`}</h2>
    <p>{`Compile the `}<inlineCode parentName="p">{`blog.thrift`}</inlineCode>{` file to generate Java code.
You can refer to the full `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/thrift/build.gradle"
      }}>{`build.gradle`}</a>{` file for generating code with `}<a parentName="p" {...{
        "href": "https://github.com/jruyi/thrift-gradle-plugin"
      }}>{`Gradle Thrift Plugin`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`./gradlew compileThrift
`}</code></pre>
    <p>{`You'll see the generated Java code in the `}<inlineCode parentName="p">{`{project_root}/build/generated-sources/thrift/gen-java/example/armeria/blog/thrift/`}</inlineCode>{` folder.`}</p>
    <h2 {...{
      "id": "next-step",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#next-step",
        "aria-label": "next step permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next step`}</h2>
    <p>{`In this step, we've defined a Thrift file for our service and generated Java code.
Next, we'll `}<a parentName="p" {...{
        "href": "/tutorials/thrift/blog/run-service"
      }}>{`run a service`}</a>{` and test the connection.`}</p>
    <TutorialSteps current={1} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      